<template>
  <b-input-group size="sm" class="w-100 mx-2" style="max-width: 400px">
    <b-input-group-prepend>
      <b-input-group-text class="py-0">
        <b-icon-search></b-icon-search>
      </b-input-group-text>
    </b-input-group-prepend>

    <input
      v-model="query"
      placeholder="Buscar por nombre, apellido, email o rol"
      type="search"
      class="form-control"
      :disabled="!$store.getters['userModule/getAvailableList']"
      @keyup.enter="search"
    />
  </b-input-group>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserSearch',

  data () {
    return {
      query: null
    }
  },

  computed: {
    ...mapGetters('userModule', ['isSearchingMode'])
  },

  watch: {
    isSearchingMode: {
      immediate: true,
      handler (value) {
        if (!value) return

        this.query = this.$store.state.userModule.query
      }
    },

    query: {
      immediate: true,
      handler (val) {
        if (!val) this.clear()
      }
    }
  },

  methods: {
    async search () {
      if (this.query === null || this.query === '') return

      this.$store.commit('userModule/setQuery', this.query)

      const { error, message } = await this.$store.dispatch('userModule/search', { search: this.query })

      if (error) this.$notify({ error, message }, 'Busqueda usuarios')
    },

    async clear () {
      this.query = null
      this.$store.commit('userModule/setQuery', this.query)
      this.$store.dispatch('userModule/getUsers')
    }
  }
}
</script>
