<template>
  <div>
    <check-authorization :requiresAuthorizations="['listar usuarios']">
      <div class="card card-header-actions mb-4">
        <b-card-header>
          Usuarios

          <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
            <!-- Form -->
            <check-authorization
              :requiresAuthorizations="['crear usuarios']"
              :overrideIf="$store.getters['userModule/isEditingUser']"
            >
              <user-form
                :value="showForm || isEditingUser"
                @input="showForm = $event"
              />
            </check-authorization>
            <!-- Search -->
            <user-search></user-search>
            <!-- Options -->
            <div class="dropdown no-caret">
              <button
                class="btn btn-transparent-dark btn-icon dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <i data-feather="more-vertical"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                <a class="dropdown-item my-cursor" @click="getUsers(null)">
                  Recargar
                </a>
              </div>
            </div>
          </div>
        </b-card-header>

        <b-card-body class="p-2">
          <user-list @on-got-item="showForm = true"></user-list>
        </b-card-body>
      </div>
    </check-authorization>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserForm from '@/components/Users/UserForm'
import UserList from '@/components/Users/UserList'
import UserSearch from '@/components/Users/UserSearch'

export default {
  name: 'Users',

  components: {
    UserForm,
    UserList,
    UserSearch
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters('userModule', ['isEditingUser']),

  methods: {
    ...mapActions('userModule', ['getUsers'])
  }
}
</script>
