<template>
  <div>
    <div class="card mb-4">
      <div class="card-header">
        Roles actuales
      </div>

      <div class="card-body">
        <div v-for="(role, index) in getSystemRoles" :key="role.id">
          <b-row>
            <!-- Atributos -->
            <b-col class="d-flex">
              <!-- icon -->
              <i class="fas fa-user-shield fa-2x cc-color-amex"></i>
              <div class="ml-4">
                <div class="small">{{ role.name }}</div>
                <div class="text-xs text-muted">{{ role.description }}</div>
              </div>
            </b-col>

            <!-- Controles -->
            <b-col>
              <b-container>
                <u-d-controls
                  :id="role.id"
                  editPath="roleModule/getRole"
                  deletePath="roleModule/deleteRole"
                  :editPermissions="['editar roles']"
                  :deletePermissions="['eliminar roles']"
                  @on-got-item="up"
                  class="my-2 justify-content-end"
                />
              </b-container>
            </b-col>
          </b-row>
          <hr v-show="index < getSystemRoles.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UDControls from '@/components/Shared/UDControls'

export default {
  name: 'RoleList',

  components: {
    UDControls
  },

  created () {
    this.$store.dispatch('roleModule/getSystemRoles')
  },

  computed: mapGetters('roleModule', ['getSystemRoles']),

  methods: {
    up () {
      window.scrollTo(0, 120)
    }
  }
}
</script>
