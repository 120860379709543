<template>
  <div>
    <!-- Table -->
    <b-table
      id="usersTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getUserList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Nombre -->
      <template #cell(persona.nombre)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.persona.nombre }}</span>
      </template>

      <!-- Email -->
      <template #cell(email)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.email }}</span>
      </template>

      <!-- Profesión -->
      <template #cell(empleado.profesion)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.empleado.profesion }}
        </span>
      </template>

      <!-- Fecha de ingreso -->
      <template #cell(empleado.fecha_ingreso)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.empleado.fecha_ingreso }}
        </span>
      </template>

      <!-- Roles -->
      <template #cell(roles_checklist)="data">
        <b-badge
          v-for="role in data.item.roles_checklist"
          :key="role.id"
          :variant="getRoleVariant(role.name)"
          class="mr-1"
        >
          {{ role.name }}
        </b-badge>
        <span v-if="data.item.roles_checklist.length <= 0" class="text-danger">
          Sin roles asignados
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>

        <check-authorization :requiresRoles="['admin']" class="w-100" v-if="!row.item.is_deleted">
          <b-button
            class="btn-sm mb-2 btn-block btn-outline-indigo"
            variant="light"
            @click="viewAsUser(row.item.id)"
            v-if="!isAuthUserOrCloneUser(row.item.id)"
          >
            Ver como
          </b-button>
        </check-authorization>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="userModule/getUserToEdit"
            deletePath="userModule/deleteUser"
            :editPermissions="['editar usuarios']"
            :deletePermissions="['eliminar usuarios']"
            :hideDeleteAction="isAuthUserOrCloneUser(row.item.id)"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Datos del usuario"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="height: 500px; overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      <b class="text-danger">
                        {{ row.item.persona.nombre }} {{ row.item.persona.primer_apellido }}
                        {{ row.item.persona.segundo_apellido }}
                      </b>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Nombre:</b>
                      {{ row.item.persona.nombre }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Primer apellido:</b>
                      {{ row.item.persona.primer_apellido }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Segundo apellido:</b>
                      {{ row.item.persona.segundo_apellido }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Email:</b>
                      {{ row.item.email }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Profesión:</b>
                      {{ row.item.empleado.profesion }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Fecha de ingreso:</b>
                      <!-- {{ $customFormatDate(row.item.empleado.fecha_ingreso, 'DD MMMM YYYY') }} -->
                      {{ row.item.empleado.fecha_ingreso }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Unidades administrativas:</b>
                      <b-badge
                        v-for="u in row.item.empleado.unidades_administrativas_nombres"
                        :key="u.id"
                        variant="light"
                        class="mx-1"
                      >
                        {{ u.nombre }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Capítulos:</b>
                      <b-badge
                        v-for="u in row.item.empleado.capitulos_nombres"
                        :key="u.id"
                        variant="light"
                        class="mx-1"
                      >
                        {{ u.nombre }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Roles asignados:</b>
                      <b-badge
                        v-for="role in row.item.roles_checklist"
                        :key="role.id"
                        class="mx-1"
                        :variant="getRoleVariant(role)"
                      >
                        {{ role.name }}
                      </b-badge>
                      <span v-if="row.item.roles_checklist.length <= 0" class="text-danger">
                        sin roles asignados
                      </span>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Último acceso:</b>
                      {{
                        row.item.ultimo_acceso === 'Sin registro de ingreso'
                          ? row.item.ultimo_acceso
                          : $formatDate(row.item.ultimo_acceso)
                      }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Fecha de creación</b>
                      {{ $formatDate(row.item.fecha_creacion) }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Última modificación</b>
                      {{ $formatDate(row.item.ultima_modificacion) }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card
                  no-body
                  header="Permisos asignados"
                  header-bg-variant="warning"
                  header-text-variant="light"
                  border-variant="warning"
                  style="height: 500px; overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item v-for="p in row.item.permissions" :key="p.id">
                      {{ p.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
    <div v-if="isThereUsers">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getUsersResource"
      ></paginator>
    </div>
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserList',

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'is_deleted', label: 'Habilitado', sortable: true },
        { key: 'persona.nombre', label: 'Nombre', sortable: true },
        { key: 'email', label: 'Email' },
        { key: 'empleado.profesion', label: 'Profesión' },
        { key: 'empleado.fecha_ingreso', label: 'Fecha de ingreso', sortable: true },
        { key: 'roles_checklist', label: 'Roles', sortable: true },
        { key: 'show_details', label: 'Detalle' },
        { key: 'acciones', label: 'Acciones' }
      ]
    }
  },

  computed: {
    ...mapGetters('userModule', [
      'getUserList',
      'isAuthUserOrCloneUser',
      'getAvailableList',
      'isThereUsers',
      'getUsersResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch('userModule/getUsers')

      if (error) this.$notify({ error, message }, 'Usuarios')
    },

    getRoleVariant (role) {
      if (role === 'admin') return 'success'

      return 'primary'
    },

    async viewAsUser (id) {
      await this.$store.dispatch('userModule/getViewAsUser', id)
      this.$router.push({ name: 'Dashboard' })
      window.scrollTo(0, 0)
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>
