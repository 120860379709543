var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEditing)?_c('div',{staticClass:"card card-icon mb-4"},[_c('div',{staticClass:"row no-gutters"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-body py-4"},[(_vm.isEditing)?_c('h5',{staticClass:"card-title text-danger"},[_vm._v(" Editando permiso: "+_vm._s(_vm.editingPermission.name)+" ")]):_c('h5',{staticClass:"card-title"},[_vm._v("Crea un nuevo permiso")]),_c('div',{staticClass:"card-text"},[_c('FormulateForm',{ref:"permissionForm",attrs:{"errors":_vm.remoteErrors},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"name":"name","disabled":"","label":"Nombre del permiso","placeholder":"Nombre del permiso","validation":"bail|required","validation-messages":{
                required: 'El nombre del permiso es requerido'
              }},model:{value:(_vm.formValues.name),callback:function ($$v) {_vm.$set(_vm.formValues, "name", $$v)},expression:"formValues.name"}}),_c('FormulateInput',{attrs:{"name":"description","label":"Descripción del permiso","placeholder":"Descripción breve sobre el permiso","type":"textarea","validation":"bail|required","validation-messages":{
                required: 'La descripción del permiso es requerido'
              }},model:{value:(_vm.formValues.description),callback:function ($$v) {_vm.$set(_vm.formValues, "description", $$v)},expression:"formValues.description"}}),_c('div',{staticClass:"d-flex"},[(_vm.isEditing)?_c('div',[_c('button',{staticClass:"btn btn-black",attrs:{"disabled":isLoading,"type":"reset"},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")])]):_vm._e(),_c('button',{staticClass:"btn ml-auto",class:_vm.updateOrCreateBtn(isLoading).class,attrs:{"type":"submit","disabled":hasErrors || isLoading}},[_vm._v(" "+_vm._s(_vm.updateOrCreateBtn(isLoading).text)+" ")])])]}}],null,false,2531321840)})],1)])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-none d-md-flex col-auto card-icon-aside bg-black"},[_c('i',{staticClass:"fas fa-bolt"})])}]

export { render, staticRenderFns }