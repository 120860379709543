<template>
  <div>
    <check-authorization
      :requiresAuthorizations="['crear roles']"
      :overrideIf="$store.getters['roleModule/isEditingRole']"
    >
      <role-form />
    </check-authorization>
    <check-authorization :requiresAuthorizations="['listar roles', 'listar permisos']">
      <role-list />
    </check-authorization>
  </div>
</template>

<script>
import RoleForm from '@/components/Roles/RoleForm'
import RoleList from '@/components/Roles/RoleList'

export default {
  name: 'Roles',

  components: {
    RoleForm,
    RoleList
  }
}
</script>

<style lang="scss" scoped></style>
