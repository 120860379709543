<template>
  <div v-if="isEditing" class="card card-icon mb-4">
    <div class="row no-gutters">
      <div class="d-none d-md-flex col-auto card-icon-aside bg-black">
        <i class="fas fa-bolt"></i>
      </div>
      <div class="col">
        <div class="card-body py-4">
          <h5 v-if="isEditing" class="card-title text-danger">
            Editando permiso: {{ editingPermission.name }}
          </h5>
          <h5 v-else class="card-title">Crea un nuevo permiso</h5>
          <div class="card-text">
            <FormulateForm
              ref="permissionForm"
              :errors="remoteErrors"
              @submit="submit"
              #default="{ hasErrors, isLoading }"
            >
              <!-- Form Group (Permission)-->
              <FormulateInput
                name="name"
                v-model="formValues.name"
                disabled
                label="Nombre del permiso"
                placeholder="Nombre del permiso"
                validation="bail|required"
                :validation-messages="{
                  required: 'El nombre del permiso es requerido'
                }"
              />

              <FormulateInput
                name="description"
                v-model="formValues.description"
                label="Descripción del permiso"
                placeholder="Descripción breve sobre el permiso"
                type="textarea"
                validation="bail|required"
                :validation-messages="{
                  required: 'La descripción del permiso es requerido'
                }"
              />

              <!-- Botones -->
              <div class="d-flex">
                <div v-if="isEditing">
                  <!-- Cancelar -->
                  <button :disabled="isLoading" @click="cancel" type="reset" class="btn btn-black">
                    Cancelar
                  </button>
                </div>

                <!-- Botón para crear o actualizar -->
                <button
                  type="submit"
                  :disabled="hasErrors || isLoading"
                  class="btn ml-auto"
                  :class="updateOrCreateBtn(isLoading).class"
                >
                  {{ updateOrCreateBtn(isLoading).text }}
                </button>
              </div>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/EventBus'
import { mapState, mapActions, mapGetters } from 'vuex'

const formValues = () => ({
  name: '',
  description: ''
})

export default {
  name: 'PermissionForm',

  data () {
    return {
      formValues: formValues(),
      remoteErrors: {}
    }
  },

  computed: {
    ...mapState('permissionModule', ['editingPermission']),

    ...mapGetters('permissionModule', ['isEditing'])
  },

  watch: {
    editingPermission: {
      immediate: true,
      deep: true,
      handler (newVal) {
        if (newVal) {
          this.formValues = newVal
        }
      }
    },

    formValues: {
      immediate: true,
      deep: true,
      handler () {
        this.remoteErrors = {}
      }
    }
  },

  methods: {
    ...mapActions('UIModule', ['setNotification']),

    async submit (form) {
      //
      // Fix: Borrar función para crear permiso,
      // de confirmar que no se podrá crear permiso desde la interfaz.
      // const getval = this.isEditing
      //   ? await this.updatePermission(this.editingPermission.id, this.editingPermission)
      //   : await this.createPermission(form)

      const getval = await this.updatePermission(this.editingPermission.id, this.editingPermission)

      this.getRemoteErrors(getval)

      EventBus.$emit('toast', 'Permisos: Envío de formulario', getval.message, {
        status: getval.error ? 'danger' : 'success'
      })

      if (!getval.error) this.resetForm()
    },

    //
    // Fix: Borrar función para crear permiso,
    // de confirmar que no se podrá crear permiso desde la interfaz.
    // async createPermission (form) {
    //   return await this.$store.dispatch('permissionModule/createPermission', form)
    // },

    async updatePermission (id, payload) {
      delete payload.name
      return await this.$store.dispatch('permissionModule/updatePermission', { id, payload })
    },

    getRemoteErrors (getval) {
      if (getval.error && getval.data.errors) {
        this.remoteErrors = getval.data.errors
      }
    },

    resetForm () {
      document.activeElement.blur()
      this.formValues = formValues()
      this.remoteErrors = {}
      this.$refs.permissionForm.hideErrors()
    },

    cancel () {
      this.$store.commit('permissionModule/setEditingPermission', null)
      this.resetForm()
    },

    /**
     * Obtiene los valores del botón en base
     * al contexto si se está actualizando o creando un recurso.
     */
    updateOrCreateBtn (isLoading) {
      return {
        class: this.isEditing ? 'btn-warning' : 'btn-success',
        text: isLoading ? 'Procesando...' : this.isEditing ? 'Actualizar' : 'Crear nuevo permiso'
      }
    }
  }
}
</script>
